import { useTranslation } from "@pancakeswap/localization";
import { useIsMounted } from "@pancakeswap/hooks";
import { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";
import { Button, RowBetween, Column, Text, TextProps, Flex, PencilIcon, WarningIcon } from "../../components";
import { useTooltip } from "../../hooks";

const ButtonStyled = styled(Button)`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray900};

  &:hover {
    background: ${({ theme }) => theme.colors.gray800};
  }
`;

export const SwapInfoLabelStyled = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SlippageValue = styled.div`
  color: ${({ theme }) => theme.colors.gray100};
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  padding: 4px 12px;
  background: ${({ theme }) => theme.colors.white50};
`;

type SwapInfoType = {
  price: ReactNode;
  allowedSlippage?: number;
  slippageError?: boolean;
  onSlippageClick?: () => void;
  allowedSlippageSlot?: React.ReactNode;
};

export const SwapInfoLabel = (props: PropsWithChildren<TextProps>) => (
  <SwapInfoLabelStyled medium color="gray200" {...props} />
);

export const SwapInfo = ({
  allowedSlippage,
  price,
  onSlippageClick,
  allowedSlippageSlot,
  slippageError,
}: SwapInfoType) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const { targetRef, tooltip, tooltipVisible } = useTooltip(t("Your transaction may fail"), {
    placement: "bottom-end",
    tooltipOffset: [10, 4],
  });

  return (
    <>
      {price && <RowBetween alignItems="center">{price}</RowBetween>}

      <ButtonStyled gap="sm" py="8px" px="16px" variant="text" onClick={onSlippageClick}>
        {typeof allowedSlippage === "number" && (
          <RowBetween alignItems="center">
            <SwapInfoLabel>
              {t("Slippage Tolerance")}
              {onSlippageClick ? <PencilIcon color="gray100" width="20px" height="20px" /> : null}
            </SwapInfoLabel>
            {isMounted &&
              (allowedSlippageSlot ?? (
                <Column gap="4px" flexDirection="row" alignItems="center">
                  <SlippageValue>{allowedSlippage / 100}%</SlippageValue>

                  {slippageError && (
                    <Flex ref={targetRef}>
                      <WarningIcon color="textSubtle" width="24px" height="24px" />
                      {tooltipVisible && tooltip}
                    </Flex>
                  )}
                </Column>
              ))}
          </RowBetween>
        )}
      </ButtonStyled>
    </>
  );
};
