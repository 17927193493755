import styled from "styled-components";
import Text from "./Text";

export const GradientText = styled(Text)`
  font-weight: 500;
  background: ${({ theme }) => theme.colors.gradientPrimary};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
