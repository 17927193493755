import { AtomBox } from "@pancakeswap/ui";
import { ReactNode, memo } from "react";
import styled from "styled-components";
import { Heading } from "../../components/Heading";
import { Text } from "../../components/Text";

interface Props {
  title: ReactNode;
  subtitle: ReactNode;
}

export const CurrencyInputHeader = memo(({ title, subtitle }: Props) => {
  return (
    <AtomBox width="100%" alignItems="center" flexDirection="column" paddingY="16px" paddingX="24px" borderBottom="1">
      <AtomBox display="flex" width="100%" alignItems="center" justifyContent="space-between">
        {title}
      </AtomBox>
      {subtitle}
    </AtomBox>
  );
});

export const CurrencyInputHeaderTitle = memo(({ children }: { children: ReactNode }) => (
  <Heading as="h2">{children}</Heading>
));

const SubtitleText = styled(Text)`
  font-size: 16px;
  letter-spacing: 0.0375rem;
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.gray200};

  ${({ theme }) => theme.mediaQueries.bdXxl} {
    font-size: 14px;
    line-height: 1.28;
    letter-spacing: 0.025rem;
  }
`;

export const CurrencyInputHeaderSubTitle = memo(({ children }: { children: ReactNode }) => (
  <SubtitleText>{children}</SubtitleText>
));
