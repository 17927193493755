import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.3 15.6 15.6 5.3a1 1 0 0 1 1.4 0L18.7 7c.4.4.4 1 0 1.4L8.4 18.7a1 1 0 0 1-.7.3H5v-2.7c0-.2.1-.5.3-.7Z"
        clipRule="evenodd"
      />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m13.8 7.2 3 3" />
    </Svg>
  );
};

export default Icon;
