import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Percent, TradeType } from '@pancakeswap/sdk'
import { Button, QuestionHelper, Text, AutoColumn } from '@pancakeswap/uikit'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row'
import { memo } from 'react'
import { Field } from 'state/swap/actions'
import styled from 'styled-components'
import { warningSeverity } from 'utils/exchange'

import FormattedPriceImpact from '../../components/FormattedPriceImpact'
import { SwapCallbackError } from '../../components/styleds'

const TextStyled = styled(Text)`
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 1.28;
`

const SpanStyled = styled.span`
  display: inline-block;
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.gray200};
`

export const SwapModalFooter = memo(function SwapModalFooter({
  priceImpact: priceImpactWithoutFee,
  lpFee: realizedLPFee,
  inputAmount,
  outputAmount,
  tradeType,
  slippageAdjustedAmounts,
  isEnoughInputBalance,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
}: {
  tradeType: TradeType
  lpFee: CurrencyAmount<Currency>
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  priceImpact: Percent
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  onConfirm: () => void
  swapErrorMessage?: string | undefined
  disabledConfirm: boolean
}) {
  const { t } = useTranslation()
  const severity = warningSeverity(priceImpactWithoutFee)

  return (
    <>
      <AutoColumn gap="8px" mt="24px" mb="12px">
        <RowBetween>
          <RowFixed>
            <TextStyled color="gray200" letterSpacing="0.5px">
              {tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}
            </TextStyled>
            <QuestionHelper
              size="16px"
              text={t(
                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
              )}
              ml="4px"
              placement="top"
            />
          </RowFixed>
          <RowFixed>
            <TextStyled color="gray100" medium>
              {tradeType === TradeType.EXACT_INPUT
                ? formatAmount(slippageAdjustedAmounts[Field.OUTPUT], 4) ?? '-'
                : formatAmount(slippageAdjustedAmounts[Field.INPUT], 4) ?? '-'}
            </TextStyled>
            <TextStyled color="gray200" marginLeft="2px">
              {tradeType === TradeType.EXACT_INPUT ? outputAmount.currency.symbol : inputAmount.currency.symbol}
            </TextStyled>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TextStyled color="gray200" letterSpacing="0.5px">
              {t('Price Impact')}
            </TextStyled>
            <QuestionHelper
              size="16px"
              text={t('The difference between the market price and your price due to trade size.')}
              ml="4px"
              placement="top"
            />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TextStyled color="gray200" letterSpacing="0.5px">
              {t('Trading Fee')}
            </TextStyled>
            <QuestionHelper
              size="16px"
              text={
                <>
                  <TextStyled>{t('Fee ranging from 0.1% to 0.01% depending on the pool fee tier.')}</TextStyled>
                </>
              }
              ml="4px"
              placement="top"
            />
          </RowFixed>
          <TextStyled color="gray100" medium textAlign="right">
            {realizedLPFee ? (
              <>
                {formatAmount(realizedLPFee, 6)}
                <SpanStyled>{inputAmount.currency.symbol}</SpanStyled>
              </>
            ) : (
              '-'
            )}
          </TextStyled>
        </RowBetween>
      </AutoColumn>

      <AutoRow>
        <Button
          variant={severity > 2 ? 'danger' : 'outline-primary'}
          onClick={onConfirm}
          disabled={disabledConfirm}
          mt="12px"
          id="confirm-swap-or-send"
          width="100%"
        >
          {severity > 2 || (tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance)
            ? t('Swap Anyway')
            : t('Confirm Swap')}
        </Button>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
})
