import { Box } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StyledPriceChart = styled(Box)<{
  $isDark: boolean
  $isExpanded: boolean
  $isFullWidthContainer?: boolean
}>`
  border: none;
  border-radius: 32px;
  width: 100%;
  padding-top: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    background: ${({ theme }) => theme.colors.tintGray80};
    border: ${({ theme }) => `1px solid ${theme.colors.gray800}`};
    border-radius: ${({ $isExpanded }) => ($isExpanded ? '0' : '16px')};
    height: ${({ $isExpanded }) => ($isExpanded ? '100%' : '516px')};
  }
`

StyledPriceChart.defaultProps = {
  height: '70%',
}
