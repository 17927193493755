import { AutoColumn, Swap as SwapUI, Button } from '@pancakeswap/uikit'
import { useCallback, memo } from 'react'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { useTranslation } from '@pancakeswap/localization'

import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { useSwapState } from 'state/swap/hooks'
import { Field } from 'state/swap/actions'

import { useAllowRecipient } from '../hooks'

export const FlipButton = memo(function FlipButton() {
  const { t } = useTranslation()
  const { onSwitchTokens, onChangeRecipient } = useSwapActionHandlers()
  const {
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const allowRecipient = useAllowRecipient()

  const onFlip = useCallback(() => {
    onSwitchTokens()
    replaceBrowserHistory('inputCurrency', outputCurrencyId)
    replaceBrowserHistory('outputCurrency', inputCurrencyId)
  }, [onSwitchTokens, inputCurrencyId, outputCurrencyId])

  return (
    <AutoColumn gap="8px" justifyItems="center">
      <SwapUI.SwitchButton onClick={onFlip} />
      {allowRecipient && recipient === null ? (
        <Button
          p="4px"
          variant="primary-text"
          id="add-recipient-button"
          onClick={() => onChangeRecipient('')}
          style={{ fontSize: '16px' }}
        >
          {t('+ Add a send (optional)')}
        </Button>
      ) : null}
    </AutoColumn>
  )
})
