import { Modal, Box } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'

const ConfirmSwapModalContainer = ({ children, handleDismiss }) => {
  const { t } = useTranslation()

  return (
    <Modal title={t('Confirm Swap')} onDismiss={handleDismiss}>
      <Box width={[null, null, null, '420px'] as any}>{children}</Box>
    </Modal>
  )
}

export default ConfirmSwapModalContainer
