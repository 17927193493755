import { useTranslation } from '@pancakeswap/localization'
import { Currency } from '@pancakeswap/sdk'
import { BottomDrawer, useMatchBreakpoints, Flex, Text, GradientText, QuestionHelper } from '@pancakeswap/uikit'
import { useContext } from 'react'
import { useExpertMode } from '@pancakeswap/utils/user'

import { useCurrency } from 'hooks/Tokens'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useSingleTokenSwapInfo, useSwapState } from 'state/swap/hooks'
import styled from 'styled-components'
import Page from '../Page'
import PriceChartContainer from './components/Chart/PriceChartContainer'
import { V3SwapForm } from './V3Swap'
import { StyledContainer, StyledContent, DesktopChartWrap, StyledInputCurrencyWrapper } from './styles'
import { SwapFeaturesContext } from './SwapFeaturesContext'

const ExpertModeText = styled(GradientText)`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.6px;
`

const ExpertModeLabel = styled.div`
  display: flex;
  padding: 4px 12px;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(
    90deg,
    rgba(238, 129, 0, 0.2) 0%,
    rgba(234, 43, 87, 0.2) 50%,
    rgba(115, 34, 166, 0.2) 100%
  );
`

export default function Swap() {
  const { isDesktop } = useMatchBreakpoints()
  const { isChartExpanded, isChartDisplayed, setIsChartDisplayed, setIsChartExpanded, isChartSupported } =
    useContext(SwapFeaturesContext)
  const [expertMode] = useExpertMode()
  const { t } = useTranslation()

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)
  useDefaultsFromURLSearch()

  return (
    <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
      <StyledContainer px={isChartExpanded ? '0' : [24, null, 32, 48, null, 80]} $isChartExpanded={isChartExpanded}>
        <StyledContent $isChartExpanded={isChartExpanded} $isChartDisplayed>
          {isDesktop && isChartSupported && (
            <DesktopChartWrap>
              <PriceChartContainer
                inputCurrencyId={inputCurrencyId}
                inputCurrency={currencies[Field.INPUT]}
                outputCurrencyId={outputCurrencyId}
                outputCurrency={currencies[Field.OUTPUT]}
                isChartExpanded={isChartExpanded}
                setIsChartExpanded={setIsChartExpanded}
                isChartDisplayed={isChartDisplayed}
                currentSwapPrice={singleTokenPrice}
              />
            </DesktopChartWrap>
          )}
          {!isDesktop && isChartSupported && (
            <BottomDrawer
              content={
                <PriceChartContainer
                  inputCurrencyId={inputCurrencyId}
                  inputCurrency={currencies[Field.INPUT]}
                  outputCurrencyId={outputCurrencyId}
                  outputCurrency={currencies[Field.OUTPUT]}
                  isChartExpanded={isChartExpanded}
                  setIsChartExpanded={setIsChartExpanded}
                  isChartDisplayed={isChartDisplayed}
                  currentSwapPrice={singleTokenPrice}
                  isFullWidthContainer
                  isMobile
                />
              }
              isOpen={isChartDisplayed}
              setIsOpen={setIsChartDisplayed}
            />
          )}

          <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'} $isChartExpanded={isChartExpanded}>
            {expertMode && (
              <ExpertModeLabel>
                <ExpertModeText>{t('Expert mode is enabled')}</ExpertModeText>
                <QuestionHelper
                  placement="bottom"
                  size="16px"
                  text={
                    <Flex flexDirection="column">
                      <Text fontSize="14px" color="gray100" lineHeight={1.28}>
                        {t(
                          'Expert mode is enabled. Now you have modal confirmations and can make high slippage trades.',
                        )}
                      </Text>
                      <Text fontSize="14px" color="gray100" mt="16px" lineHeight={1.28}>
                        {t('You can disable this mode in the settings.')}
                      </Text>
                    </Flex>
                  }
                  ml="4px"
                />
              </ExpertModeLabel>
            )}
            <V3SwapForm />
          </StyledInputCurrencyWrapper>
        </StyledContent>
      </StyledContainer>
    </Page>
  )
}
