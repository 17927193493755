import { ExpandIcon, Flex, IconButton, ShrinkIcon, SyncAltIcon, Text } from '@pancakeswap/uikit'
import { CurrencyLogo, DoubleCurrencyLogo } from 'components/Logo'
import styled from 'styled-components'
import BasicChart from './BasicChart'
import { StyledPriceChart } from './styles'

const TokensWrap = styled(Flex)`
  padding: 8px 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray900};
`

const IconButtonWrap = styled(IconButton)`
  padding: 8px;
  width: 40px;
  height: 40px;
`
const PriceChart = ({
  inputCurrency,
  outputCurrency,
  onSwitchTokens,
  isDark,
  isChartExpanded,
  setIsChartExpanded,
  isMobile,
  isFullWidthContainer,
  token0Address,
  token1Address,
  currentSwapPrice,
}) => {
  const toggleExpanded = () => setIsChartExpanded((currentIsExpanded) => !currentIsExpanded)

  return (
    <StyledPriceChart
      height="70%"
      overflow="unset"
      $isDark={isDark}
      $isExpanded={isChartExpanded}
      $isFullWidthContainer={isFullWidthContainer}
    >
      <Flex justifyContent="space-between" px="24px" mb="8px">
        <TokensWrap alignItems="center">
          {outputCurrency ? (
            <DoubleCurrencyLogo currency0={inputCurrency} currency1={outputCurrency} size={16} margin />
          ) : (
            inputCurrency && <CurrencyLogo currency={inputCurrency} size="16px" style={{ marginRight: '4px' }} />
          )}
          {inputCurrency && (
            <Text color="gray100" medium>
              {outputCurrency ? `${inputCurrency.symbol}/${outputCurrency.symbol}` : inputCurrency.symbol}
            </Text>
          )}
          <IconButton variant="text" scale="sm" ml="4px" onClick={onSwitchTokens}>
            <SyncAltIcon width="16px" color="gray200" />
          </IconButton>
        </TokensWrap>
        {!isMobile && (
          <Flex>
            <IconButtonWrap variant="text" onClick={toggleExpanded}>
              {isChartExpanded ? (
                <ShrinkIcon color="gray100" width="24px" />
              ) : (
                <ExpandIcon color="gray100" width="24px" />
              )}
            </IconButtonWrap>
          </Flex>
        )}
      </Flex>
      <BasicChart
        token0Address={token0Address}
        token1Address={token1Address}
        isChartExpanded={isChartExpanded}
        isMobile={isMobile}
        currentSwapPrice={currentSwapPrice}
      />
    </StyledPriceChart>
  )
}

export default PriceChart
