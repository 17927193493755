import { Price, Currency } from "@pancakeswap/swap-sdk-core";
import { formatPrice } from "@pancakeswap/utils/formatFractions";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import { useState } from "react";
import { iconButtonClass } from "./SwapWidget.css";
import { SyncAltIcon } from "../../components/Svg";
import { Text } from "../../components/Text";
import { IconButton } from "../../components/Button";
import { Loading } from "../../components";

interface TradePriceProps {
  price?: Price<Currency, Currency>;
  loading?: boolean;
}

export function TradePrice({ price, loading }: TradePriceProps) {
  const [showInverted, setShowInverted] = useState<boolean>(false);

  const formattedPrice = showInverted ? formatPrice(price, 6) : formatPrice(price?.invert(), 6);
  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);

  return (
    <Text
      medium
      color="gray200"
      style={{ justifyContent: "center", alignItems: "center", display: "flex", opacity: loading ? 0.6 : 1 }}
    >
      {show ? (
        <>
          {`1 ${showInverted ? price?.baseCurrency?.symbol : price?.quoteCurrency?.symbol}`}
          {loading ? (
            <AtomBox className={iconButtonClass}>
              <Loading width="12px" height="12px" />
            </AtomBox>
          ) : (
            <IconButton variant="text" scale="sm" onClick={() => setShowInverted(!showInverted)}>
              <SyncAltIcon width="16px" height="16px" color="gray200" />
            </IconButton>
          )}
          {`${formattedPrice} ${showInverted ? price?.quoteCurrency?.symbol : price?.baseCurrency?.symbol}`}
        </>
      ) : (
        "-"
      )}
    </Text>
  );
}
