import throttle from 'lodash/throttle'
import { useMemo, useState } from 'react'
import { AppBody } from 'components/App'

import { useDerivedSwapInfo, useSwapState } from 'state/swap/hooks'
import { useCurrency } from 'hooks/Tokens'
import { Field } from 'state/swap/actions'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useUserStableSwapEnable } from 'state/user/smartRouter'
import { useUserSlippage } from '@pancakeswap/utils/user'
import { FormHeader, FormMain, PricingAndSlippage, SwapCommitButton, TradeDetails } from './containers'
import { useRefreshBlockNumber } from '../hooks/useRefreshBlockNumber'
import { useDerivedSwapInfoWithStableSwap } from './hooks/useDerivedSwapInfoWithStableSwap'
import { useTradeInfo } from './hooks/useTradeInfo'
import { useIsSmartRouterBetter } from './hooks/useIsSmartRouterBetter'

export function V3SwapForm() {
  const [allowUseSmartRouter] = useState(false)
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumber()
  const { chainId } = useActiveWeb3React()
  const [isStableSwapByDefault] = useUserStableSwapEnable()

  const { v2Trade: trade, inputError: swapInputError } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient,
  )
  const {
    trade: tradeWithStableSwap,
    parsedAmount,
    inputError: stableSwapInputError,
  } = useDerivedSwapInfoWithStableSwap(independentField, typedValue, inputCurrency, outputCurrency)
  const [allowedSlippage] = useUserSlippage()

  const isSmartRouterBetter = useIsSmartRouterBetter({ trade: tradeWithStableSwap, v2Trade: trade })

  const hasAmount = Boolean(parsedAmount)

  const throttledHandleRefresh = useMemo(
    () =>
      throttle(() => {
        if (hasAmount) refreshBlockNumber()
      }, 3000),
    [hasAmount, refreshBlockNumber],
  )

  const tradeInfo = useTradeInfo({
    trade: tradeWithStableSwap,
    v2Trade: trade,
    useSmartRouter: (allowUseSmartRouter || isStableSwapByDefault) && isSmartRouterBetter,
    allowedSlippage,
    chainId,
    swapInputError,
    stableSwapInputError,
  })

  const finalTrade = trade

  const tradeLoaded = !!trade

  return (
    <>
      <AppBody>
        <FormHeader onRefresh={throttledHandleRefresh} refreshDisabled={!tradeLoaded} />
        <FormMain
          tradeLoading={!tradeLoaded}
          pricingAndSlippage={
            <PricingAndSlippage priceLoading={isLoading} price={tradeInfo?.executionPrice} showSlippage />
          }
          inputAmount={finalTrade?.inputAmount}
          outputAmount={finalTrade?.outputAmount}
          swapCommitButton={
            <SwapCommitButton
              trade={trade}
              tradeError={swapInputError}
              tradeLoading={!tradeLoaded}
              parsedAmount={parsedAmount}
              inputAmount={tradeInfo?.inputAmount}
              outputAmount={tradeInfo?.outputAmount}
            />
          }
        />
      </AppBody>

      <TradeDetails loaded={tradeLoaded} trade={trade} />
    </>
  )
}
