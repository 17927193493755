import styled from 'styled-components'

export const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  margin-top: ${({ show }) => (show ? '12px' : 0)};
  padding: 24px;
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray1000};

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`
