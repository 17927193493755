import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.6 12.6c.5 0 1-.2 1.4-.6l4.6-5.5a.8.8 0 0 0-1.2-1L15.8 11l-.2.1-2-.5 2 2ZM2.3 4.3l1.5 1.5V19c0 .7.5 1.2 1.2 1.2h13.2l1.5 1.5H5A2.7 2.7 0 0 1 2.2 19V4.2Zm8.9 8.9-3.6 4.3a.7.7 0 1 1-1.2-1l3.7-4.4 1 1Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" fillRule="evenodd" d="m22.5 21.5-20-20 1-1 20 20-1 1Z" clipRule="evenodd" />
    </svg>
  );
};

export default Icon;
