import { CurrencyAmount, Currency, Percent } from '@pancakeswap/sdk'
import { Button, Text, ErrorIcon, ArrowDownIcon, AutoColumn, Flex } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import { warningSeverity } from 'utils/exchange'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from '@pancakeswap/utils/truncateHash'
import styled from 'styled-components'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'

const TokensWrap = styled(AutoColumn)`
  padding: 16px;
  border-radius: 8px;
  gap: 8px;
  background: ${({ theme }) => theme.colors.gray800};
`

export default function SwapModalHeader({
  inputAmount,
  outputAmount,
  priceImpactWithoutFee,

  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  priceImpactWithoutFee?: Percent
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const { t } = useTranslation()

  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <AutoColumn gap="md">
      <TokensWrap>
        <RowBetween align="center">
          <Flex alignItems="center">
            <CurrencyLogo currency={inputAmount.currency} size="28px" style={{ marginRight: '8px' }} />
            <TruncatedText medium letterSpacing="0.2px" color="gray100">
              {formatAmount(inputAmount, 6)}
            </TruncatedText>
          </Flex>
          <Text medium color="gray300">
            {inputAmount.currency.symbol}
          </Text>
        </RowBetween>
        <RowFixed>
          <ArrowDownIcon width="16px" ml="7px" color="gray300" />
        </RowFixed>
        <RowBetween align="center">
          <Flex alignItems="center">
            <CurrencyLogo currency={outputAmount.currency} size="28px" style={{ marginRight: '8px' }} />
            <TruncatedText medium letterSpacing="0.2px" color={priceImpactSeverity > 2 ? 'failure' : 'gray100'}>
              {formatAmount(outputAmount, 6)}
            </TruncatedText>
          </Flex>
          <Text medium color="gray300">
            {outputAmount.currency.symbol}
          </Text>
        </RowBetween>
      </TokensWrap>

      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0px">
          <RowBetween>
            <Flex>
              <ErrorIcon mr="8px" color="warning" />
              <Text medium color="warning">
                {t('Price Updated')}
              </Text>
            </Flex>
            <Button variant="outline-primary" scale="sm" onClick={onAcceptChanges}>
              {t('Accept')}
            </Button>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}

      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
