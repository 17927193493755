/* eslint-disable @typescript-eslint/no-unused-vars */
import { Currency, Percent, Router, SwapParameters, Trade, TradeType } from '@pancakeswap/sdk'
import { useMemo } from 'react'

import useAccountActiveChain from 'hooks/useAccountActiveChain'
import { Address } from 'viem'
import { useRouterContract } from 'utils/exchange'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { INITIAL_ALLOWED_SLIPPAGE } from 'config/constants'
import { BIPS_BASE } from 'config/constants/exchange'

export interface SwapCall {
  contract: any
  parameters: SwapParameters
}

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param recipientAddressOrName
 */
export function useSwapCallArguments(
  trade: Trade<Currency, Currency, TradeType> | undefined | null,
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE,
  recipientAddress: string | null | undefined,
): SwapCall[] {
  const { account, chainId } = useAccountActiveChain()
  const recipient = (recipientAddress === null ? account : recipientAddress) as Address | null
  const deadline = useTransactionDeadline()
  const contract = useRouterContract()

  return useMemo(() => {
    if (!trade || !recipient || !account || !chainId || !deadline) return []

    if (!contract) {
      return []
    }

    const swapMethods = []

    swapMethods.push(
      Router.swapCallParameters(trade, {
        feeOnTransfer: false,
        allowedSlippage: new Percent(BigInt(allowedSlippage), BIPS_BASE),
        recipient,
        deadline: Number(deadline),
      }),
    )

    if (trade.tradeType === TradeType.EXACT_INPUT) {
      swapMethods.push(
        Router.swapCallParameters(trade, {
          feeOnTransfer: true,
          allowedSlippage: new Percent(BigInt(allowedSlippage), BIPS_BASE),
          recipient,
          deadline: Number(deadline),
        }),
      )
    }

    return swapMethods.map((parameters) => ({ parameters, contract }))
  }, [account, allowedSlippage, chainId, contract, deadline, recipient, trade])
}
