import {
  ChartDisableIcon,
  ChartIcon,
  Flex,
  HistoryIcon,
  IconButton,
  NotificationDot,
  Swap,
  useModal,
} from '@pancakeswap/uikit'
import RefreshIcon from 'components/Svg/RefreshIcon'
import { CHAIN_REFRESH_TIME } from 'config/constants/exchange'
import { useExpertMode } from '@pancakeswap/utils/user'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useRoutingSettingChanged } from 'state/user/smartRouter'
import { useAtom } from 'jotai'
import { ReactElement, useContext, useEffect, memo } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import atomWithStorageWithErrorCatch from 'utils/atomWithStorageWithErrorCatch'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'
import { SwapFeaturesContext } from '../SwapFeaturesContext'

interface Props {
  title: string | ReactElement
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const TopFlex = styled(Flex)`
  width: 100%;
  row-gap: 12px;

  ${({ theme }) => theme.mediaQueries.bdXxl} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.Xl} {
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
  }
`

const TopTitlesFlex = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.bdXxl} {
    row-gap: 2px;
  }
`

const StyledTopButtonsFlex = styled(Flex)`
  width: 100%;
  gap: 8px;
`

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.gray100};
  background: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  padding: 8px;
  overflow: hidden;
  min-width: 40px;
  width: 100%;
`

const StyledGlobalSettings = styled(GlobalSettings)`
  color: ${({ theme }) => theme.colors.gray100};
  background: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  padding: 8px;
  min-width: 40px;
  width: 100%;
`

//  disable this during the v3 campaign
const mobileShowOnceTokenHighlightAtom = atomWithStorageWithErrorCatch('pcs::mobileShowOnceTokenHighlightV2', true)

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = memo(
  ({ subtitle, title, hasAmount, onRefreshPrice }) => {
    const { chainId } = useActiveChainId()
    const [mobileTooltipShowOnce, setMobileTooltipShowOnce] = useAtom(mobileShowOnceTokenHighlightAtom)

    const { isChartSupported, isChartDisplayed, setIsChartDisplayed } = useContext(SwapFeaturesContext)
    const [expertMode] = useExpertMode()
    const [isRoutingSettingChange] = useRoutingSettingChanged()
    const toggleChartDisplayed = () => {
      setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
    }
    const [onPresentTransactionsModal] = useModal(<TransactionsModal />)

    useEffect(() => {
      if (isMobile && !mobileTooltipShowOnce) {
        setMobileTooltipShowOnce(true)
      }
    }, [mobileTooltipShowOnce, setMobileTooltipShowOnce])

    const titleContent = (
      <TopFlex>
        <TopTitlesFlex flexDirection="column" width="100%">
          <Swap.CurrencyInputHeaderTitle>{title}</Swap.CurrencyInputHeaderTitle>
          <Swap.CurrencyInputHeaderSubTitle>{subtitle}</Swap.CurrencyInputHeaderSubTitle>
        </TopTitlesFlex>

        <StyledTopButtonsFlex>
          {isChartSupported && setIsChartDisplayed && (
            <StyledIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
              {isChartDisplayed ? <ChartDisableIcon width="24px" /> : <ChartIcon width="24px" />}
            </StyledIconButton>
          )}
          <NotificationDot fullWidth show={expertMode || isRoutingSettingChange}>
            <StyledGlobalSettings mode={SettingsMode.SWAP_LIQUIDITY} />
          </NotificationDot>
          <StyledIconButton onClick={onPresentTransactionsModal} variant="text" scale="sm">
            <HistoryIcon width="25px" />
          </StyledIconButton>
          <StyledIconButton variant="text" scale="sm" onClick={onRefreshPrice}>
            <RefreshIcon
              disabled={!hasAmount}
              width="25px"
              duration={CHAIN_REFRESH_TIME[chainId] ? CHAIN_REFRESH_TIME[chainId] / 1000 : undefined}
            />
          </StyledIconButton>
        </StyledTopButtonsFlex>
      </TopFlex>
    )

    return <Swap.CurrencyInputHeader title={titleContent} subtitle={<></>} />
  },
)

export default CurrencyInputHeader
