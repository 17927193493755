import { SvgProps } from '@pancakeswap/uikit'

// NOTE: Temporary save icon here. Will move to uikit when the feature is done

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Icon = ({ disabled, duration, ...props }: SvgProps & { disabled?: boolean; duration?: number | string }) => (
  <svg id="arrow_loading" fill="none" viewBox="0 0 25 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M20 4.6v3.6h-3.5H20"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.5 8.2a8 8 0 1 0 1 3.8"
    />
  </svg>
)

export default Icon
