import { Box, ButtonMenu, ButtonMenuItem, Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useState, memo, useMemo } from 'react'
import { useFetchPairPricesV3 } from 'state/swap/hooks'
import dynamic from 'next/dynamic'
import { PairDataTimeWindowEnum } from 'state/swap/types'
import styled from 'styled-components'
import NoChartAvailable from './NoChartAvailable'
import PairPriceDisplay from '../../../../components/PairPriceDisplay'
import { getTimeWindowChange } from './utils'

const Header = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 24px;

  ${({ theme }) => theme.mediaQueries.Xxl} {
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.mediaQueries.bdXxl} {
    flex-direction: column;
    align-items: stretch;
  }
`

const PriceFlex = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 8px 16px;
  }
`
const ChangeText = styled(Text)`
  font-size: 12px;
  line-height: 1.333;
  letter-spacing: 0.6px;
  padding: 2px 8px;
  align-self: flex-start;
  border-radius: 10px;
`

const SwapLineChart = dynamic(() => import('@pancakeswap/uikit/src/components/Chart/PairPriceChart'), {
  ssr: false,
})

const BasicChart = ({ token0Address, token1Address, isChartExpanded, isMobile, currentSwapPrice }) => {
  const [timeWindow, setTimeWindow] = useState<PairDataTimeWindowEnum>(0)

  const { data: pairPrices = [] } = useFetchPairPricesV3({
    token0Address,
    token1Address,
    timeWindow,
    currentSwapPrice,
  })

  const [hoverValue, setHoverValue] = useState<number | undefined>()
  const [hoverDate, setHoverDate] = useState<string | undefined>()
  const valueToDisplay = hoverValue || pairPrices[pairPrices.length - 1]?.value
  const {
    changePercentage: changePercentageToCurrent,
    changeValue: changeValueToCurrent,
    isChangePositive: isChangePositiveToCurrent,
  } = useMemo(() => getTimeWindowChange(pairPrices), [pairPrices])
  const { changePercentage, changeValue, isChangePositive } = useMemo(() => {
    if (hoverValue) {
      const lastItem = pairPrices[pairPrices.length - 1]
      if (lastItem) {
        const copyPairPrices = [...pairPrices]
        copyPairPrices[pairPrices.length - 1] = { ...lastItem, value: hoverValue }
        return getTimeWindowChange(copyPairPrices)
      }
    }
    return {
      changePercentage: changePercentageToCurrent,
      changeValue: changeValueToCurrent,
      isChangePositive: isChangePositiveToCurrent,
    }
  }, [pairPrices, hoverValue, changePercentageToCurrent, changeValueToCurrent, isChangePositiveToCurrent])
  const chartHeight = useMemo(() => (isChartExpanded ? 'calc(100vh - 220px)' : '320px'), [isChartExpanded])
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const currentDate = useMemo(() => {
    if (!hoverDate) {
      return new Date().toLocaleString(locale, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    }
    return null
  }, [hoverDate, locale])

  // Sometimes we might receive array full of zeros for obscure tokens while trying to derive data
  // In that case chart is not useful to users
  const isBadData = useMemo(
    () =>
      pairPrices &&
      pairPrices.length > 0 &&
      pairPrices.every(
        (price) => !price.value || price.value === 0 || price.value === Infinity || Number.isNaN(price.value),
      ),
    [pairPrices],
  )

  if (isBadData) {
    return <NoChartAvailable token0Address={token0Address} token1Address={token1Address} isMobile={isMobile} />
  }

  return (
    <>
      <Header>
        <PriceFlex alignItems="center">
          <PairPriceDisplay value={pairPrices?.length > 0 && valueToDisplay} />
          {pairPrices?.length > 0 && valueToDisplay && (
            <Flex flexDirection="column" style={{ gap: '2px' }}>
              <ChangeText
                color={isChangePositive ? 'success' : 'failure'}
                style={{ background: isChangePositive ? 'rgba(41, 184, 55, 0.10)' : 'rgba(243, 52, 52, 0.10)' }}
                medium
              >
                {`${isChangePositive ? '+' : ''}${changeValue.toFixed(3)} (${changePercentage}%)`}
              </ChangeText>
              <Text fontSize="12px" color="gray200" medium lineHeight={1.333} letterSpacing="0.6px">
                {hoverDate || currentDate}
              </Text>
            </Flex>
          )}
        </PriceFlex>
        <ButtonMenu activeIndex={timeWindow} onItemClick={setTimeWindow} scale="sm">
          <ButtonMenuItem>{t('24H')}</ButtonMenuItem>
          <ButtonMenuItem>{t('1W')}</ButtonMenuItem>
          <ButtonMenuItem>{t('1M')}</ButtonMenuItem>
          <ButtonMenuItem>{t('1Y')}</ButtonMenuItem>
        </ButtonMenu>
      </Header>
      <Box height={isMobile ? '100%' : chartHeight} p={isMobile ? '0px' : '24px'} width="100%">
        <SwapLineChart
          data={pairPrices}
          setHoverValue={setHoverValue}
          setHoverDate={setHoverDate}
          isChangePositive={isChangePositiveToCurrent}
          isChartExpanded={isChartExpanded}
          timeWindow={timeWindow}
        />
      </Box>
    </>
  )
}

export default memo(BasicChart, (prev, next) => {
  return (
    prev.token0Address === next.token0Address &&
    prev.token1Address === next.token1Address &&
    prev.isChartExpanded === next.isChartExpanded &&
    prev.isMobile === next.isMobile &&
    prev.isChartExpanded === next.isChartExpanded &&
    ((prev.currentSwapPrice !== null &&
      next.currentSwapPrice !== null &&
      prev.currentSwapPrice[prev.token0Address] === next.currentSwapPrice[next.token0Address] &&
      prev.currentSwapPrice[prev.token1Address] === next.currentSwapPrice[next.token1Address]) ||
      (prev.currentSwapPrice === null && next.currentSwapPrice === null))
  )
})
